/** @format */

import axios, { AxiosInstance, AxiosRequestConfig, AxiosResponse } from "axios";
import { Procuretoast } from "src/utils/Toast";
import { Intent } from "@blueprintjs/core";
import cookie from "react-cookies";
import { ErrorLog } from "src/api/api";
// 自定义请求头类型
interface CustomHeaders {
	"Content-Type"?: string;
	[key: string]: string | undefined;
}
// 定义请求参数类型
interface ApiResponse<T> {
	url: string;
	method: "GET" | "POST" | "PUT" | "DELETE";
	headers?: CustomHeaders;
	params?: Record<string, any>;
	data?: Record<string, any>;
}
class ApiClient {
	private axiosInstance: AxiosInstance;

	constructor(baseURL: string) {
		this.axiosInstance = axios.create({
			baseURL,
			headers: {
				"Access-Control-Allow-Origin": "*",
				"Content-Type": "application/json",
				Authorization: cookie.load("datacenter_token"),
			},
			timeout: 3000000,
		});

		// 请求拦截器
		this.axiosInstance.interceptors.request.use((config) => {
			// 可以在此处添加一些全局请求拦截的代码，例如添加 token 等
			// let { url } = config;
			// if (url !== "/center/login/" && cookie.load("datacenter_token")) {
			// 	window.location.href = "/";
			// }

			return config;
		});

		// 响应拦截器
		this.axiosInstance.interceptors.response.use(
			(response) => {
				// 可以在此处添加一些全局响应拦截的代码

				if (response.status === 201) {
					// 请求成功
					Procuretoast(response.data.msg, Intent.SUCCESS);
				} else if (response.status === 204) {
					Procuretoast("已删除", Intent.DANGER);
				}
				return response;
			},
			(error) => {
				// 可以在此处添加一些全局错误处理的代码
				if (error.response.status == 401) {
					Procuretoast("登录失效，请重新登录", Intent.DANGER);
					cookie.remove("datacenter_token");
					window.location.href = "/login";
				} else {
					Procuretoast(error.message, Intent.WARNING);
				}
				if (error.code !== "ERR_CANCELED"&&error.response.status != 403) {
					//开发模式不调用ErrorLog
					if (process.env.NODE_ENV === "production" && error.response.status !== 404 && error.response.code !== 'ERR_NETWORK') {
						ErrorLog({
							error_catch: error.response.data ? error.response.data : error,
							error_url: error.config.url,
							data: error.config.data,
							params: error.config.params,
							error_method: error.config.method,
						});
					}
				}
				throw error;
			},
		);
	}

	public async get<T>(url: string, params?: Record<string, any>): Promise<T> {
		const response: AxiosResponse<ApiResponse<T>> =
			await this.axiosInstance.get(url, {
				params,
			});
		return response.data as any;
	}

	public async post<T>(
		url: string,
		data?: Record<string, any>,
		headers?: CustomHeaders,
	): Promise<T> {
		const response: AxiosResponse<ApiResponse<T>> =
			await this.axiosInstance.post(url, data, { headers });

		return response as any;
	}

	public async put<T>(url: string, data?: any): Promise<T> {
		const response: AxiosResponse<ApiResponse<T>> =
			await this.axiosInstance.put(url, data);
		return response as any;
	}

	public async delete<T>(
		url: string,
		params?: AxiosRequestConfig["params"],
	): Promise<T> {
		const response: AxiosResponse<ApiResponse<T>> =
			await this.axiosInstance.delete(url, {
				params,
			});
		return response.data as any;
	}
}

export default ApiClient;
